import Css from "./style.module.scss";

import { NavLink } from "react-router-dom";
import { checkTasksFetching, getActiveTasksData } from "selectors/tasks";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import Features from "const/Features";
import React, { useCallback } from "react";
import TaskListItem from "nlib/common/TaskListItem";
import TasksActions from "actions/TasksActions";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import classNames from "classnames";
import useAllowedFeature from "hooks/useAllowedFeature";
import useEnvVars from "hooks/useEnvVars";

const TASKS_LIST_MAX_COUNT = 5;

const TasksBlock = ({ className }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const checkIsAllowed = useAllowedFeature();

  const userRole = useSelector(getUserRole);

  const activeTasksData = useSelector(getActiveTasksData);

  const tasksFetching = useSelector(checkTasksFetching);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const [, setEnvVars] = useEnvVars();

  const fetchData = useCallback(() => {
    dispatch(TasksActions.fetchTasksList());
  }, [dispatch]);

  const handleSelectTask = useCallback((taskId) => {
    setEnvVars({ editTask: taskId, editItem: null });
  }, [setEnvVars]);

  if (!checkIsAllowed(Features.TASKS) || !activeTasksData.length) return null;

  return (
    <div className={classNames(Css.tasksBlock, className)}>
      <div className={Css.row}>
        <div className={Css.title}>{businessUser ? uiTexts.tasks : uiTexts.nonTransactionTasks}</div>
        <NavLink to={`/${selectedBusinessId}${UiRoutes.TASKS}`}>{uiTexts.showAllTasks}</NavLink>
      </div>
      {activeTasksData.slice(0, TASKS_LIST_MAX_COUNT).map((task) => (
        <TaskListItem
          key={task.id}
          task={task}
          disabled={tasksFetching}
          onSelect={handleSelectTask}
          fetchData={fetchData} />
      ))}
    </div>
  );
};

export default React.memo(TasksBlock);
