import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Checkbox from "nlib/ui/Checkbox";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import TaskStatuses from "const/TaskStatuses";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import useShowCommonModal from "hooks/useShowCommonModal";
import useTaskContent from "hooks/useTaskContent";

const {
  HIGH_PRIORITY,
  COMPLETED,
  EXPIRED
} = TaskStatuses;

const MAX_TASK_PREVIEW_LENGTH = 256;

const STATUSES_CLASS_NAMES = {
  [COMPLETED]: Css.completed,
  [EXPIRED]: Css.expired,
  [HIGH_PRIORITY]: Css.highPriority
};

const TaskListItem = ({ disabled, task, onSelect, fetchData }) => {
  const {
    id: taskId,
    preview,
    comments,
    completed,
    createdBy,
    targetUser,
    attachmentsCount
  } = task;

  const {
    dueDateContent,
    status,
    creationDate,
    createdByContent,
    taskCreatedByYourSide
  } = useTaskContent(task);

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { id: userId, role: userRole } = useSelector(getUserData);

  const { uiTexts, messages } = useSelector(getTextsData);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const canBeManaged = useMemo(() => {
    return userRole === UserRoles.ACCOUNTANT_ADMIN.roleId || (createdBy && createdBy.id === userId);
  }, [userRole, createdBy, userId]);

  const taskForContent = useMemo(() => {
    if (targetUser) {
      if (targetUser.id === userId) return uiTexts.forYou;

      const { fullName: userFullName } = targetUser;

      return Utils.replaceTextVars(uiTexts.forUserName, { userName: userFullName });
    }

    if ((taskCreatedByYourSide && businessUser) || (!taskCreatedByYourSide && !businessUser)) {
      return uiTexts.forAccountant;
    }

    return uiTexts.forClient;
  }, [targetUser, taskCreatedByYourSide, businessUser, uiTexts, userId]);

  const createdByAtContent = useMemo(() => {
    return [createdBy && createdByContent, creationDate].filter(Boolean).join(", ");
  }, [createdBy, createdByContent, creationDate]);

  const handleItemClick = useCallback(() => {
    onSelect(taskId);
  }, [taskId, onSelect]);

  const handleCompleteClick = useCallback(async(event) => {
    event.stopPropagation();

    const result = await dispatch(TasksActions.editTask(taskId, !completed));

    if (result) {
      const { COMMENT_TARGET_TYPES: { TASKS }, COMMENT_TYPES: { COMMENT } } = DataConstants;

      await dispatch(
        CommentsActions.addNewComment(
          COMMENT,
          completed ? messages.unmarkedAsCompleted : messages.markedAsCompleted,
          [],
          false,
          taskId,
          TASKS
        )
      );
      if (fetchData && !completed) fetchData();
    }
  }, [taskId, completed, messages, dispatch, fetchData]);

  const handleRemoveClick = useCallback(async(event) => {
    event.stopPropagation();

    let result = await showCommonModal({ confirm: true, text: messages.taskDeleteConfirm });

    if (!result) return;

    result = await dispatch(TasksActions.deleteTask(taskId));
  }, [taskId, messages, dispatch, showCommonModal]);

  return (
    <div
      disabled={disabled}
      className={classNames(
        Css.taskListItem,
        targetUser && targetUser.id === userId && Css.forYou,
        STATUSES_CLASS_NAMES[status]
      )}
      data-status={status}
      data-for={targetUser && targetUser.id === userId ? "you" : "other"}
      onClick={handleItemClick}>
      <div
        className={Css.checkBox}
        title={completed ? uiTexts.unmarkAsComplete : uiTexts.markAsComplete}
        onClick={handleCompleteClick}>
        <Checkbox checked={completed} />
      </div>
      <div className={Css.content}>
        {preview + (preview.length >= MAX_TASK_PREVIEW_LENGTH ? "..." : "")}
      </div>
      <div className={Css.dueDate}>
        {!!dueDateContent && <div>{dueDateContent}</div>}
      </div>
      <div className={Css.user}>
        {taskForContent && (
          <>
            <Icons.User weight="bold" />
            <span title={taskForContent}>{taskForContent}</span>
          </>
        )}
      </div>
      <div className={Css.createdBy}>
        <span title={createdByAtContent}>{createdByAtContent}</span>
      </div>
      <div className={Css.info}>
        <div className={classNames(Css.item, Css.attachments)}>
          {!!attachmentsCount && (
            <>
              <Icons.FileText />
              <Badge counter>{attachmentsCount}</Badge>
            </>
          )}
        </div>
        <div className={classNames(Css.item, Css.comments)}>
          <Icons.ChatText />
          <Badge counter theme={comments.unread && "attention"}>{comments.all}</Badge>
        </div>
        <div className={Css.item}>
          {canBeManaged && (
            <Button
              light
              className={Css.remove}
              onClick={handleRemoveClick}>
              <Icons.Trash />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TaskListItem);
